import { BaseContext, BaseMutations } from "./types";
import { CustomUmbrella } from "@/models/configurator/custom_umbrella";

import { BaseModel } from "@/models/products/base";
import { Finish } from "@/models/products/finish";
import { Stem } from "@/models/configurator/configurator_options";

export const addBase = (context: BaseContext, value: BaseModel) => {
  context.commit(BaseMutations.SetBase, value);
};

export const addBaseFinish = (context: BaseContext, value: Finish) => {
  context.commit(BaseMutations.SetBaseFinish, value);
};

export const addBaseStem = (context: BaseContext, value: Stem | null) => {
  context.commit(BaseMutations.SetBaseStem, value);
};

export const addBaseOptions = (context: BaseContext, value: any) => {
  context.commit(BaseMutations.SetBaseOptions, value);
};

export const addAllBaseData = (
  context: BaseContext,
  value: CustomUmbrella["base"]
) => {
  context.commit(BaseMutations.SetBase, value.base);
  context.commit(BaseMutations.SetBaseFinish, value.baseFinish);
  context.commit(BaseMutations.SetBaseStem, value.baseStem);
  context.commit(BaseMutations.SetBaseOptions, value.baseOptions);
};
